<template>
<div class="Map w-full">
    <yandex-map @click="clicked" :coords="coords" style="height: 100vh; width: 100%" :zoom="14" :controls="['zoomControl']">
        <ymap-marker :coords="coords" marker-id="123" hint-content="some hint" />
    </yandex-map>
    <div class="coor-elements">
        <div class="coor-element">
            <div><input class="coor-input" id="coords" type="hide" readonly :value="coordcopyed"> </div>
            <div class="copy-button">
                <el-tooltip class="item" effect="dark" content="Кўчириш" placement="bottom-start">
                    <button @click="copyCoord">
                        <svg version="1.1" id="Capa_1" width="20" height="20" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 210.107 210.107" style="enable-background:new 0 0 210.107 210.107;" xml:space="preserve">
                            <g>
                                <path d="M168.506,0H80.235C67.413,0,56.981,10.432,56.981,23.254v2.854h-15.38

c-12.822,0-23.254,10.432-23.254,23.254v137.492c0,12.822,10.432,23.254,23.254,23.254h88.271

c12.822,0,23.253-10.432,23.253-23.254V184h15.38c12.822,0,23.254-10.432,23.254-23.254V23.254C191.76,10.432,181.328,0,168.506,0z

 M138.126,186.854c0,4.551-3.703,8.254-8.253,8.254H41.601c-4.551,0-8.254-3.703-8.254-8.254V49.361

c0-4.551,3.703-8.254,8.254-8.254h88.271c4.551,0,8.253,3.703,8.253,8.254V186.854z M176.76,160.746

c0,4.551-3.703,8.254-8.254,8.254h-15.38V49.361c0-12.822-10.432-23.254-23.253-23.254H71.981v-2.854

c0-4.551,3.703-8.254,8.254-8.254h88.271c4.551,0,8.254,3.703,8.254,8.254V160.746z" />
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                        </svg>
                    </button>
                </el-tooltip>
            </div>
        </div>
        <div class="coor-element" style="width:200px;">
            <div>{{$t('change')}}:</div>
            <div class="copy-button">
                <el-tooltip class="item" effect="dark" :content="$t('change')" placement="bottom-start">
                    <el-switch v-model="disabled"></el-switch>
                </el-tooltip>
            </div>
        </div>
    </div>
    <div class="bottom-element">
            <a href="https://unicon.uz/"><div>UNICON.UZ</div></a>
    </div>
    <!-- <div class="auto-fill" v-if="disabled">
        <button @click="Save">Сақлаш</button>
    </div> -->
</div>
</template>

<script>
export default {
    name: 'Map',
    data() {
        return {
            coords: [41.31629324503914, 69.26717199864542],
            initialCoords: [41.31629324503914, ],
            coordcopyed: '41.31629324503914,69.26717199864542',
            disabled:true
        }
    },
    mounted() {
        if(this.$route.query.coor!='undefined' && this.$route.query.coor.length>30 ){
            this.toArray(this.$route.query.coor)
        }
        
        
    },
    methods: {
        toArray(a) {
            if (a) {
                this.coordcopyed = a
                a = a.split(',')
                this.coords = a
                this.disabled=false
            } else {
                return false
            }
        },
        panToFirst() {
            this.coords = this.initialCoords;

        },
        clicked(e) {
            if (this.disabled) {
                this.coords = e.get("coords");
                this.coordcopyed = this.coords[0] + "," + this.coords[1]
            }
        },
        Save(){
            var a=this.coords
            if(Array.isArray(this.coords)){
                a=this.coords.join()
            }
            this.$store.commit('set_coord', a) 
        },
        panToEntered(e) {
            this.coords = this.spec1.geolocation.split(',');
        },
        copyCoord() {
            let code = document.querySelector('#coords')
            code.setAttribute('type', 'text')
            code.select()

            try {
                var successful = document.execCommand('copy');
                this.$swal.fire({
                    icon: 'success',
                    title: "Координаталар кўчирилди",
                    timer: 3000
                });
            } catch (err) {
                alert('Oops, unable to copy');
            }
            testingCodeToCopy.setAttribute('type', 'hidden')
            window.getSelection().removeAllRanges()
        },
    }
}
</script>

<style scoped>
.coor-elements {

    position: absolute !important;

    top: 5px;
    left: 10px;

}

.coor-element {
    background: #fff;
    color: rgb(0, 0, 0);
    font-weight: 600;
    font-size: 16px;
    border-radius: 5px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    padding: 10px 20px;
    margin-bottom: 5px;
}

.copy-button {
    margin-left: 20px;
}

.coor-input {
    min-width: 320px;
    width: auto;
    color: rgb(0, 0, 0);
    border: none;
    outline: none;
    font-weight: 600;
    font-size: 16px;
}

.copy-button button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.copy-button button svg {
    fill: black;
}
.bottom-element{
    position: absolute;
    cursor: pointer;
    bottom: 10px;
    right: 10px;
    background: #fff;
    color: rgb(2, 2, 174);
    font-weight: 600;
    font-size: 16px;
    border-radius: 5px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    padding: 10px 20px;
    margin-bottom: 5px;
}
.auto-fill{
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
}
.auto-fill button{
    padding: 20px 40px;
    background: rgb(2, 2, 174);
    color: #fff;
    font-weight: 600;
    border-radius: 10px;

}
</style>
